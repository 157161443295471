import './StrictTab.scss';

const StrictTab = (props) => {
    return (
        <div className="strict">
            <h1>Coming soon</h1>
        </div>
    )
}

export default StrictTab;